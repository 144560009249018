import getCookie from '../cookies/getCookies';
import getIp from '../cookies/getIp';

const submitToHubspot = async (uid: string, htmlId: string, getFields: (data: any) => void) => {
  console.log(uid);
  const hubspotURL = `https://api.hsforms.com/submissions/v3/integration/submit/20433357/${uid}`;
  //console.log({ hubspotURL });
  // Get my form element
  const emailForm = document.getElementById(htmlId) as HTMLFormElement;

  if (emailForm && emailForm.parentElement) {
    //emailForm.parentElement.classList.remove('w-form');
    // console.log({ emailForm });

    // Add own submit handler
    emailForm.onsubmit = async (event) => {
      try {
        event.preventDefault();

        // Get the form data
        const formData = new FormData(emailForm);

        // Get the form entries as an object
        const data = Object.fromEntries(formData.entries());

        console.log({ data });

        const fields = getFields(data);

        console.log({ fields });
        const ip = await getIp();

        const response = await fetch(hubspotURL, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            fields,
            context: { hutk: getCookie('hubspotutk'), ipAddress: ip ? ip : null },
          }),
        });

        if (response.status !== 200) {
          throw new Error('Response status is not 200');
        }

        // Handle the response
        // const responseData = await response.json();

        // console.log({ responseData });
      } catch (e) {
        // 7. Handle the error
        if (e instanceof Error) {
          throw e;
        }
      }
    };
  }
};

export default submitToHubspot;
