const trackSpeakWithUsModal = () => {
  const urlString = window.location.href;
  const url = new URL(urlString);

  const modal = document.getElementById('speak-with-us-modal');

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      // Check if opacity has changed
      if (mutation.attributeName === 'style') {
        const opacity = modal && parseFloat(modal.style.opacity);
        // Add queryparam if the modal is shown
        if (!url.searchParams.has('swu1') && opacity === 1) {
          url.searchParams.set('swu1', 'true');
          window.history.replaceState({}, '', url.href);
        }
        // Remove queryparam if the modal is not shown
        if (url.searchParams.has('swu1') && opacity === 0) {
          url.searchParams.delete('swu1');
          window.history.replaceState({ id: 100 }, 'Page', urlString.split('?')[0]);
        }
      }
    });
  });

  const config = { attributes: true };
  if (modal) observer.observe(modal, config);
};

export default trackSpeakWithUsModal;
