const generateFieldObject = (name: string, value: any) => ({
  objectTypeId: '0-1',
  name,
  value,
});

export const basicFields = (data: any) => [
  generateFieldObject('email', data.email),
  generateFieldObject('agreed_to_privacy_policy', data.privacy === 'on' ? 'Yes' : 'No'),
];

export const speakWithUsFormFields = (data: any) => {
  const issues = Object.keys(data)
    .filter(
      (d) =>
        !(
          d === 'email' ||
          d === 'country' ||
          d === 'company_type_updated' ||
          d === 'message' ||
          d === 'privacy'
        )
    )
    .map((i) => i.replace(/-/g, ' '))
    .join('; ');
  return [
    ...basicFields(data),
    generateFieldObject('country', data.country),
    generateFieldObject('company_type_updated_v2', data.company_type_updated),
    generateFieldObject('problems_to_solve', issues),
    generateFieldObject('message', data.message),
    generateFieldObject('identifier', window.location.pathname),
  ];
};

export const generalFormFields = (data: any) => {
  let formSub: any[] = [];

  if (data.phone) {
    formSub = [...formSub, generateFieldObject('phone', data.phone)];
  }
  if (data.country) {
    formSub = [...formSub, generateFieldObject('country', data.country)];
  }
  return [
    ...formSub,
    generateFieldObject('firstname', data.firstname),
    generateFieldObject('lastname', data.lastname),
    generateFieldObject('company', data.company),
    ...basicFields(data),
  ];
};

export const webinarRegistrationFields = (data: any) => {
  let submission: any[] = [];

  if (data.pwc_data_processing)
    submission = [
      ...submission,
      generateFieldObject('pwc_data_processing', data.pwc_data_processing === 'on' ? 'Yes' : 'No'),
    ];
  if (data.pwc_marketing_consent)
    submission = [
      ...submission,
      generateFieldObject(
        'pwc_marketing_consent',
        data.pwc_marketing_consent === 'on' ? 'Yes' : 'No'
      ),
    ];

  return [...submission, ...generalFormFields(data)];
};

export const testimonialFormFields = (data: any) => [
  ...generalFormFields(data),
  generateFieldObject('company', data.company),
  generateFieldObject('testimonial_identifier', data.testimonialIdentifier),
];
