import openSpeakWithUsModal from './utils/openSpeakWithUsModal';
import submitToHubspot from './utils/hubspot/submitToHubspot';
import {
  basicFields,
  generalFormFields,
  speakWithUsFormFields,
  testimonialFormFields,
  webinarRegistrationFields,
} from './utils/hubspot/fields';
import trackSpeakWithUsModal from './utils/trackSpeakWithUsModal';

if (typeof window !== 'undefined') {
  window.Webflow?.push(async () => {
    try {
      // open speak with us modal via query param
      openSpeakWithUsModal();

      // track speak with us modal being open
      trackSpeakWithUsModal();

      // speak with us form
      submitToHubspot(
        'cbf49afa-9194-438f-94f0-cf3f67590c2f',
        'schedule-v2-form',
        speakWithUsFormFields
      );

      // speak with us form on lp
      submitToHubspot(
        'cbf49afa-9194-438f-94f0-cf3f67590c2f',
        'schedule-v2-form-lp',
        speakWithUsFormFields
      );

      // Get guid that applies to all dynamic forms
      const guidInputField = document.getElementById('hubspot_guid') as HTMLInputElement;
      const guid = guidInputField ? guidInputField.value : '';

      // Get guid that applies to all dynamic forms
      const guidRequestInputField = document.getElementById(
        'hubspot_guid_request'
      ) as HTMLInputElement;
      const guidRequest = guidRequestInputField ? guidRequestInputField.value : '';

      // Webinar request form
      submitToHubspot(guidRequest, 'webinar-request-form', generalFormFields);

      // Webinar registration form
      submitToHubspot(guid, 'webinar-registration-form', webinarRegistrationFields);

      // Newsletter form
      submitToHubspot(guid, 'newsletter-form', basicFields);

      // Resources form
      submitToHubspot(guid, 'resource-form', generalFormFields);

      // Testimonial form
      submitToHubspot(guid, 'testimonial-form', testimonialFormFields);
    } catch (e) {
      console.error('error', e);
    }
  });
}
