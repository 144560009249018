export default async () => {
  try {
    const response = await fetch(`https://ipinfo.io?token=dc8dd2910877f4`, {
      headers: {
        Accept: 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error('Response status is not 200');
    }

    const responseData = await response.json();
    return responseData.ip;
  } catch (e) {
    // 7. Handle the error
    if (e instanceof Error) {
      throw e;
    }
  }
};
