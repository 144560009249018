const openSpeakWithUsModal = () => {
  const urlString = window.location.href;
  const url = new URL(urlString);

  const openSpeakWithUsModal = url.searchParams.get('openModal');

  if (openSpeakWithUsModal && openSpeakWithUsModal === 'true') {
    document.getElementById('nav-button')?.click();
    window.history.replaceState({ id: 100 }, 'Page', urlString.split('?')[0]);
  }
};

export default openSpeakWithUsModal;
